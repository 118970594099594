import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import {zohoMultiStringToArray} from './Utils';

function CandidateCellRenderer( params ) {

  function contactButtonClicked(zohoId) {
    const targetLocation = `mailto:talents@gamesfactorytalents.com?subject=Contact%20talent%20${zohoId}`;
    window.location = targetLocation;
  }

  const candidateIdString = params.data['Candidate ID'] ? <span>{params.data['Candidate ID']}</span> : <span>&nbsp;</span>;
  const jobTitleLine = params.data.jobTitle ? <span>{params.data.jobTitle}</span> : <span>&nbsp;</span>;
  const specialitiesArray = params.data.specialities ? zohoMultiStringToArray( params.data.specialities) : [];
  const filteredSpecialitiesArray = specialitiesArray.filter(s => s.length > 0);
  const specialitiesLine = filteredSpecialitiesArray.map((spec, idx) => <Chip key={`sp-${idx}-${spec}`} label={spec}/>);
  const skillsArray = params.data.skills ? zohoMultiStringToArray( params.data.skills) : [];
  const platformsArray = params.data.platforms ? zohoMultiStringToArray( params.data.platforms) : [];
  const filteredSkillsArray = skillsArray.filter(s => s.length > 0);
  const filteredPlatformsArray = platformsArray.filter(p => p.length > 0);
  const skillsLine = filteredSkillsArray.map((spec, idx) => <Chip key={`sk-${idx}-${spec}`} className="value-chip" color="primary" label={spec}/>);
  const platformsLine = filteredPlatformsArray.map((pl, idx) => <Chip key={`sk-${idx}-${pl}`} className="value-chip" color="primary" label={pl}/>);
  const hasExperienceInGameIndustry = params.data.hasExperienceInGameIndustry === 'Yes' ? 'Yes' : 'No' ;

  const seniorityString = params.data.seniorityLevel ? <span>{params.data.seniorityLevel}</span> : <span>&nbsp;</span>;
  const countryString = params.data.country ? <span>{params.data.country}</span> : <span>&nbsp;</span>;

  return(
      <div className="candidateRowCell">
          <div className="cand-detail-row">
              <div className="cand-detail-block">

                      <div className="elem top">
                          <div className="country light">
                              {countryString}
                          </div>
                          <div class="id light">
                              ID: {candidateIdString}
                          </div>
                      </div>

                      <div className="elem">
                          <div className="title">
                              {jobTitleLine}
                          </div>
                          <div class="seniority light">
                              {seniorityString}
                          </div>
                      </div>


                      <div className="elem info">
                          <div className="relocate light">
                              Ready to relocate: {params.data.readyToRelocate}
                          </div>
                          <div className="experience light">
                              Experience in Games industry: {hasExperienceInGameIndustry}
                          </div>
                      </div>
                      <div className="elem">
                          <div className="sm-title light">
                              Specialities:
                          </div>
                          <div className="elem-values">
                              {specialitiesLine}
                          </div>
                      </div>
                      <div className="elem">
                          <div className="sm-title light">
                              Skills:
                          </div>
                          <div className="elem-values">
                              {skillsLine}
                          </div>
                      </div>

                      {platformsLine.length > 0 && (
                          <div className="elem">
                              <div className="sm-title light">
                                  Platforms:
                              </div>
                              <div className="elem-values">
                                  {platformsLine}
                              </div>
                          </div>
                      )}

                      {
                          <div className="elem buttons">
                              <Button variant="contained" onClick={() => {contactButtonClicked(params.data['Candidate ID'])}}>Request to see my full profile</Button>
                          </div>
                      }
              </div>
          </div>
      </div>


  );
}

export default CandidateCellRenderer;
